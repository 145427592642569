import React from 'react';
import { CardPropView } from '../../EssenceCard/models';
import { cnVideoCard } from '../VideoCard';
import { Picture } from '@dataartdev/uikit/Picture';
import { IPicture } from '@commonTypes/picture';

interface Props {
  view: CardPropView;
  picture?: IPicture;
}

export const VideoPreview: React.FC<Props> = ({ view, picture }) => {
  return (
    <>
      {view !== CardPropView.TEXT && view !== CardPropView.TEXT2 && picture && (
        <div className={cnVideoCard('Photo')}>
          <div className={cnVideoCard('Play')} />
          <Picture {...picture} />
        </div>
      )}
    </>
  );
};

export default VideoPreview;
