import './ClutchForm.scss';

import React, { useRef, useState } from 'react';
import { Typography } from '@dataartdev/uikit/Typography';
import { useSelector } from 'react-redux';
import { dictionariesSelector, layoutSelector } from 'store';
import { TextField } from '@dataartdev/uikit/TextField';
import { Button } from '@dataartdev/uikit/Button';
import { Checkbox } from '@dataartdev/uikit/Checkbox';
import { Picture } from '@dataartdev/uikit/Picture';
import { Grid, GridItem } from '@dataartdev/uikit/Grid';
import { Slider, SliderItem } from '@dataartdev/uikit/Slider';
import { mutationClutchForm } from 'network';
import { IconArrowRight } from '@dataartdev/uikit/IconArrowRight';
import { cnModalPage } from '../ModalPage';
import parse from 'html-react-parser';
import { schemaLetsTalkAll } from 'common/utils';
import TagManager from 'react-gtm-module';
import { recaptchaKey, recaptchaFormKey } from 'common/constants';
import ReCAPTCHA from 'react-google-recaptcha';
import { usePagePath } from '../../../hooks/usePagePath';
import { nanoid } from '@reduxjs/toolkit';
import { useFormik } from 'formik';
import { IClutchForm, TModalData } from '../models';
import { useCameFrom } from '../../../hooks/useCameFrom';

interface Props {
  onSubmitForm: (val: boolean) => void;
  modalData: TModalData;
}

function isClutchForm(data: TModalData): data is IClutchForm {
  return 'testimonials' in data;
}

export const ClutchForm: React.FC<Props> = ({ onSubmitForm, modalData }) => {
  const { companySiteForm } = useSelector(dictionariesSelector) || {};
  const {
    companySiteFormClutch: {
      companySiteFormClutchAddInfo = '',
      companySiteFormClutchSubscribePolicy = '',
      companySiteFormClutchRequiredField = '',
      companySiteFormClutchEmail = '',
      companySiteFormClutchName = '',
      companySiteFormClutchPhone = '',
      companySiteFormClutchSuccessText = '',
      companySiteFormClutchSuccessTitle = '',
      companySiteFormClutchCompany = '',
      companySiteFormClutchTrackingID = '',
    } = {},
  } = companySiteForm || {};
  const [successForm, setSuccessForm] = useState<boolean>(false);
  const { pageId } = useSelector(layoutSelector) || {};
  const reCaptchaRef = useRef<ReCAPTCHA>(null);

  const pagePath = usePagePath();
  const { cameFrom } = useCameFrom();
  const submitButtonID = companySiteFormClutchTrackingID || '';

  const schema = schemaLetsTalkAll(companySiteFormClutchRequiredField);
  const initValues = {
    email: '',
    name: '',
    company: '',
    phone: '',
    additionalInfo: '',
    privacyAgreement: false,
  };
  const {
    isSubmitting,
    errors,
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    touched,
    dirty,
  } = useFormik({
    initialValues: initValues,
    validationSchema: schema,
    onSubmit: async (values, actions) => {
      try {
        const token = await reCaptchaRef.current?.executeAsync();
        await mutationClutchForm({
          body: {
            ...values,
            pageId,
            [`${recaptchaFormKey}`]: token,
            cameFrom: cameFrom,
            trackingId: submitButtonID,
          },
        });
        onSubmitForm(true);
        setSuccessForm(true);
        TagManager.dataLayer({
          dataLayer: {
            event: 'send_form',
            form_name: 'subscribe_form',
            page_path: pagePath,
            label: values.email,
            tags: 'not set',
          },
        });
        actions.resetForm();
      } catch (error) {
        error instanceof Error
          ? console.error(error.message)
          : console.error(error);
      }
    },
  });

  const sliderOptions = {
    watchOverflow: true,
    spaceBetween: 24,
    autoHeight: true,
    pagination: {
      clickable: true,
    },
  };

  return (
    <div className={cnModalPage({ clutch: true })}>
      <Grid breakpoints={{ desktop: { cols: 12, gap: '5xl' } }}>
        <GridItem breakpoints={{ desktop: { col: 5 } }}>
          <Typography.Title size="md" className={cnModalPage('Title')}>
            {modalData.title}
          </Typography.Title>
          <Typography.Text className={cnModalPage('Text')}>
            {modalData.text}
          </Typography.Text>

          <div className={cnModalPage('Clutch-Pictures')}>
            {isClutchForm(modalData) &&
              modalData.picture.map(item => (
                <Picture {...item} key={nanoid()} />
              ))}
          </div>

          {isClutchForm(modalData) &&
            modalData?.testimonials &&
            modalData?.testimonials?.length > 0 && (
              <Slider className={cnModalPage('Slider')} {...sliderOptions}>
                {modalData.testimonials.map(item => (
                  <SliderItem key={item.id}>
                    {item.text && (
                      <Typography.Text
                        weight="bold"
                        className={cnModalPage('Slider-Text')}
                      >
                        {parse(item.text)}
                      </Typography.Text>
                    )}
                    {item.title && (
                      <Typography.Text className={cnModalPage('Slider-Title')}>
                        {item.title}
                      </Typography.Text>
                    )}
                  </SliderItem>
                ))}
              </Slider>
            )}
        </GridItem>
        <GridItem breakpoints={{ desktop: { col: 7 } }}>
          <form className={cnModalPage('Body')} onSubmit={handleSubmit}>
            <Grid
              breakpoints={{
                desktop: { cols: 12, gap: 'm' },
                tablet: { cols: 8, gap: 'm' },
                mobile: { cols: 1, gap: 'm' },
              }}
            >
              <GridItem
                breakpoints={{
                  desktop: { col: 12 },
                  tablet: { col: 8 },
                }}
              >
                <TextField
                  name="name"
                  onChange={({ e }) => handleChange(e)}
                  onBlur={handleBlur}
                  value={values.name}
                  label={companySiteFormClutchName}
                  view="outline"
                  width="full"
                  caption={
                    touched.name && errors.name ? errors.name : undefined
                  }
                  status={touched.name && !!errors.name ? 'alert' : undefined}
                />
              </GridItem>
              <GridItem
                breakpoints={{
                  desktop: { col: 12 },
                  tablet: { col: 8 },
                }}
              >
                <TextField
                  name="phone"
                  onChange={({ e }) => handleChange(e)}
                  value={values.phone}
                  onBlur={handleBlur}
                  label={companySiteFormClutchPhone}
                  view="outline"
                  width="full"
                  type="tel"
                  caption={
                    touched.phone && errors.phone ? errors.phone : undefined
                  }
                  status={touched.phone && !!errors.phone ? 'alert' : undefined}
                />
              </GridItem>
              <GridItem
                breakpoints={{
                  desktop: { col: 12 },
                  tablet: { col: 8 },
                }}
              >
                <TextField
                  name="email"
                  onChange={({ e }) => handleChange(e)}
                  value={values.email}
                  onBlur={handleBlur}
                  label={companySiteFormClutchEmail}
                  view="outline"
                  width="full"
                  required
                  caption={
                    touched.email && errors.email ? errors.email : undefined
                  }
                  status={touched.email && !!errors.email ? 'alert' : undefined}
                />
              </GridItem>
              <GridItem
                breakpoints={{
                  desktop: { col: 12 },
                  tablet: { col: 8 },
                }}
              >
                <TextField
                  name="company"
                  onChange={({ e }) => handleChange(e)}
                  value={values.company}
                  onBlur={handleBlur}
                  label={companySiteFormClutchCompany}
                  view="outline"
                  width="full"
                  caption={
                    touched.company && errors.company
                      ? errors.company
                      : undefined
                  }
                  status={
                    touched.company && !!errors.company ? 'alert' : undefined
                  }
                />
              </GridItem>
              <GridItem
                breakpoints={{
                  desktop: { col: 12 },
                  tablet: { col: 8 },
                }}
              >
                {' '}
                <TextField
                  name="additionalInfo"
                  onChange={({ e }) => handleChange(e)}
                  value={values.additionalInfo}
                  onBlur={handleBlur}
                  label={companySiteFormClutchAddInfo}
                  view="outline"
                  width="full"
                  caption={
                    touched.additionalInfo && errors.additionalInfo
                      ? errors.additionalInfo
                      : undefined
                  }
                  status={
                    touched.additionalInfo && !!errors.additionalInfo
                      ? 'alert'
                      : undefined
                  }
                />
              </GridItem>
              <GridItem
                breakpoints={{
                  desktop: { col: 12 },
                  tablet: { col: 8 },
                }}
                className={cnModalPage('Bottom')}
              >
                <Checkbox
                  required
                  label={companySiteFormClutchSubscribePolicy}
                  onBlur={handleBlur}
                  onChange={({ e }) => handleChange(e)}
                  name="privacyAgreement"
                  checked={values.privacyAgreement}
                />
                <ReCAPTCHA
                  ref={reCaptchaRef}
                  sitekey={recaptchaKey}
                  size="invisible"
                />
                <div className={cnModalPage('Button')}>
                  <Button
                    id={companySiteFormClutchTrackingID}
                    iconRight={IconArrowRight}
                    disabled={
                      isSubmitting || !dirty || !!Object.keys(errors).length
                    }
                    label={'Submit'}
                    type="submit"
                  />
                </div>
              </GridItem>
            </Grid>
          </form>
        </GridItem>
      </Grid>
      {successForm && (
        <div className={cnModalPage('Success')}>
          {companySiteFormClutchSuccessTitle && (
            <Typography.Title size="md" className={cnModalPage('Title')}>
              {companySiteFormClutchSuccessTitle}
            </Typography.Title>
          )}
          {companySiteFormClutchSuccessText && (
            <Typography.Text className={cnModalPage('SubTitle')}>
              {companySiteFormClutchSuccessText}
            </Typography.Text>
          )}
        </div>
      )}
    </div>
  );
};
