import React, { useMemo } from 'react';
import { Tag } from '@dataartdev/uikit/Tag';
import { cnVideoCard } from '../VideoCard';
import { CardPropView } from '../../EssenceCard/models';
import { ITag } from '@commonTypes/common';

interface Props {
  view: CardPropView;
  hrvTags: ITag[] | undefined;
  tagsList: ITag[] | undefined;
}

export const VideoTags: React.FC<Props> = ({
  view,
  hrvTags = [],
  tagsList = [],
}) => {
  const tags = useMemo(
    () => (
      <>
        <div className={cnVideoCard('Tags')}>
          {hrvTags.length > 0 && (
            <>
              {hrvTags.map(tag => (
                <div key={tag.id} className={cnVideoCard('Tag')}>
                  <Tag mode="link" label={tag.title} size="s" />
                </div>
              ))}
            </>
          )}
          {tagsList.length > 0 && (
            <>
              {tagsList.map(tag => (
                <div key={tag.id} className={cnVideoCard('Tag')}>
                  <Tag mode="link" label={tag.title} size="s" />
                </div>
              ))}
            </>
          )}
        </div>
      </>
    ),
    [hrvTags, tagsList]
  );
  return (
    <>
      {view === CardPropView.TEXT3 && tags}

      {view !== CardPropView.TEXT &&
        view !== CardPropView.TEXT3 &&
        view !== CardPropView.FILLED &&
        tags}
    </>
  );
};
