import { DataMutationFunction } from 'network/models';
import { httpClient } from 'network';

export const mutationMainAllForm: DataMutationFunction = variables => {
  const { body = {} } = variables || {};

  const form_data = new FormData();

  Object.keys(body).forEach(key => {
    const formattedValue = body[key]?.toString() ?? '';
    form_data.append(key, formattedValue);
  });

  return httpClient.post('/formbuilder/submit', form_data);
};
