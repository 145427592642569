import './VideoCard.scss';

import React from 'react';
import { usePropsHandler } from '../EventInterceptor/usePropsHandler';
import { cn } from '../../utils/bem';
import {
  cardPropSizeDefault,
  cardPropSizeDirection,
  cardPropViewDefault,
} from '../EssenceCard/models';
import { useModal } from './hooks/useModal';
import { VideoModal } from './components/VideoModal';
import VideoPreview from './components/VideoPreview';
import { IVideoCard } from './types/models';
import { VideoContent } from './components/VideoContent';

export const cnVideoCard = cn('VideoCard');

export const VideoCard: React.FC<IVideoCard> = props => {
  const {
    view = cardPropViewDefault,
    direction = cardPropSizeDirection,
    size = cardPropSizeDefault,
    picture,
    style,
    url, // with watch param in link
    youTubeEmbededUrl, // with embed param in link
  } = usePropsHandler(cnVideoCard(), props);

  const { isModalOpen, openModal, closeModal } = useModal();

  return (
    <>
      <div className={cnVideoCard({ view, size, direction })} style={style}>
        {url && <div className={cnVideoCard('Link')} onClick={openModal} />}
        <VideoPreview view={view} picture={picture} />
        <VideoContent {...props} />
      </div>
      <VideoModal
        isModalOpen={isModalOpen}
        onClose={closeModal}
        url={youTubeEmbededUrl}
      />
    </>
  );
};
