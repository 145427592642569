import React from 'react';
import { CardPropView, cardPropViewDefault } from '../../EssenceCard/models';
import { Typography } from '@dataartdev/uikit/Typography';
import { VideoTags } from './VideoTags';
import { Button } from '@dataartdev/uikit/Button';
import { cnVideoCard } from '../VideoCard';
import { IVideoCard } from '../types/models';
import { IconYouTube } from '@dataartdev/uikit/IconYouTube';

export const VideoContent: React.FC<IVideoCard> = props => {
  const {
    view = cardPropViewDefault,
    postDate,
    hrvTags,
    tags: tagsList,
    title,
    bottomDescription,
    linkIcon = IconYouTube,
    onClickButton,
    descriptionSmall,
    iconSize = 'm',
    linkTitle,
    detailLinkTitle,
    type,
    videoSectionCategory,
    url,
  } = props || {};

  const onClickButtonHandler = () => {
    if (url) {
      window.open(url, '_blank');
    }
  };

  return (
    <div className={cnVideoCard('Content')}>
      {(postDate || descriptionSmall || tagsList || title || linkTitle) && (
        <div className={cnVideoCard('Body')}>
          {view !== CardPropView.TEXT &&
            view !== CardPropView.FILLED &&
            postDate && (
              <div className={cnVideoCard('Date')}>
                <Typography.Text weight="semibold" size="xs">
                  {postDate}
                </Typography.Text>
              </div>
            )}

          {(videoSectionCategory || type?.name) && (
            <Typography.Text
              weight="bold"
              transform="uppercase"
              size="xs"
              className={cnVideoCard('Type')}
            >
              {videoSectionCategory || type?.name}
            </Typography.Text>
          )}

          {descriptionSmall && (
            <div className={cnVideoCard('DescriptionSmall')}>
              <Typography.Text weight="bold" size="xs" transform="uppercase">
                {descriptionSmall}
              </Typography.Text>
            </div>
          )}

          {title && (
            <div className={cnVideoCard('Title')}>
              <Typography.Title weight="bold" size="tiny" as="h3">
                {title}
              </Typography.Title>
            </div>
          )}

          <VideoTags view={view} hrvTags={hrvTags} tagsList={tagsList} />

          {view !== CardPropView.TEXT &&
            view !== CardPropView.TEXT2 &&
            (linkTitle || detailLinkTitle) && (
              <div className={cnVideoCard('Button')}>
                <Button
                  view="link-secondary"
                  iconRight={linkIcon}
                  iconSize={iconSize}
                  label={detailLinkTitle ?? linkTitle}
                  onClick={onClickButton || onClickButtonHandler}
                />
              </div>
            )}
        </div>
      )}

      {bottomDescription && (
        <Typography.Text
          size="s"
          view="web"
          className={cnVideoCard('BottomDescription')}
        >
          {bottomDescription}
        </Typography.Text>
      )}
    </div>
  );
};
