//@ts-ignore
import sbjs from 'sourcebuster';

type TF = () => { cameFrom: string };

export const useCameFrom: TF = () => {
  (sbjs as any).init();
  const cameFrom = `typ: ${sbjs.get.current.typ} | src: ${sbjs.get.current.src} | mdm: ${sbjs.get.current.mdm} | cmp: ${sbjs.get.current.cmp} | cnt: ${sbjs.get.current.cnt} | trm: ${sbjs.get.current.trm}`;

  return {
    cameFrom,
  };
};
