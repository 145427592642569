import React from 'react';
import { Button } from '@dataartdev/uikit/Button';
import { IconClose } from '@dataartdev/uikit/IconClose';
import { Modal } from '@dataartdev/uikit/Modal';
import { cnVideoCard } from '../VideoCard';

interface Props {
  isModalOpen: boolean;
  onClose: () => void;
  url: string | undefined;
}

export const VideoModal: React.FC<Props> = ({ isModalOpen, onClose, url }) => {
  return (
    <Modal
      className={cnVideoCard('Modal')}
      isOpen={isModalOpen}
      hasOverlay
      width="full"
      onOverlayClick={onClose}
      onEsc={onClose}
    >
      <iframe
        style={{ border: 'none' }}
        title="modal-iframe"
        src={url}
        width="100%"
        height="100%"
      />

      <Button
        view="ghost"
        iconRight={IconClose}
        className="Modal-Close"
        onClick={onClose}
      />
    </Modal>
  );
};
