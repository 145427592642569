import React from 'react';
import { cn } from '../../utils/bem';
import { Typography } from '@dataartdev/uikit/Typography';
import { Button } from '@dataartdev/uikit/Button';
import './HeadingLinkCard.scss';
import { prepareButtonAttrs } from '../../utils/prepareButtonAttrs';

export const cnHeadingLinkCard = cn('HeadingLinkCard');

interface Props {
  link?: string;
  linkText?: string;
  targetBlank: boolean;
  title?: string;
}

export const HeadingLinkCard: React.FC<Props> = props => {
  const { link, linkText, targetBlank, title } = props || {};
  const buttonAttr = prepareButtonAttrs(link, linkText, targetBlank);
  return (
    <div className={cnHeadingLinkCard()}>
      {title && (
        <Typography.Title size="md" as="h3">
          {title}
        </Typography.Title>
      )}
      <Button view="primary" iconSize="m" {...buttonAttr} />
    </div>
  );
};
