import React from 'react';
import { VideoCard } from '../components/VideoCard/VideoCard';
import { FeaturedCard } from '../components/FeaturedCard/FeaturedCard';
import { CardPropView } from '../components/EssenceCard/models';
import { HeadingLinkCard } from '../components/HeadingLinkCard/HeadingLinkCard';

export const renderReference = props => {
  const { contentType, item } = props;

  switch (contentType) {
    case 0:
    case 1:
    case 2:
    case 4:
    case 5:
    case 8:
    case 9:
    case 10:
      return <FeaturedCard view={CardPropView.FILLED} {...item} />;
    case 6:
      return <HeadingLinkCard {...props} />;
    // VideoCard
    case 3:
      return <VideoCard view={CardPropView.FEED} {...item} />;

    default:
      return <>none contentType in renderReference</>;
  }
};
