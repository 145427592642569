import './SearchForm.scss';
import React, { useState, useEffect } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import {
  dictionariesSelector,
  setSearchModalVisible,
  setSearchRequest,
} from 'store';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { PATH } from 'router';

import { Button } from '@dataartdev/uikit/Button';
import { TextField } from '@dataartdev/uikit/TextField';
import { IconArrowRight } from '@dataartdev/uikit/IconArrowRight';

import { cn } from 'ui/utils/bem';
import { ITags } from '../../../network/api/searchPage/models';
import { TextFieldPropOnChange } from '@dataartdev/uikit/__internal__/src/components/TextField/helpers';

type Props = {
  tagsData: Array<ITags> | undefined;
  onClickHandler: () => void;
};

const cnSearchForm = cn('SearchForm');

export const SearchForm: React.FC<Props> = props => {
  const { tagsData, onClickHandler } = props || {};

  const [searchWord, setSearchWord] = useState<string | null>('');
  const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false);
  const [tagsDataDrop, setTagsDataDrop] = useState<Array<ITags> | undefined>(
    []
  );
  const { companySiteSearchPage } = useSelector(dictionariesSelector) || {};
  const placeholderText =
    companySiteSearchPage?.companySiteSearchPageSearchPlaceholder || 'search';

  const dispatch = useDispatch();
  const { locale } = useIntl();

  useEffect(() => {
    if (!searchWord) {
      setIsDropdownVisible(false);
    }
    if (searchWord && searchWord?.length > 0) {
      setTagsDataDrop(
        tagsData?.filter((tag: ITags) =>
          tag.tagName.toLowerCase().includes(searchWord.toLowerCase())
        )
      );
      setIsDropdownVisible(true);
    }
  }, [searchWord, tagsData]);

  const history = useHistory();

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (!searchWord || searchWord.length < 2) {
      return;
    }
    onClickHandler();
    dispatch(setSearchModalVisible(false));
    dispatch(setSearchRequest(searchWord));
    history.push(
      generatePath(`${PATH.SEARCH_PAGE}?name=${searchWord}`, {
        lang: locale === 'en' ? null : locale,
      })
    );
  };

  const onSearchHandler: TextFieldPropOnChange = e => setSearchWord(e?.value);

  return (
    <div className={cnSearchForm()}>
      <form className={cnSearchForm('Inner')} onSubmit={handleSubmit}>
        <div className={cnSearchForm('Input')}>
          <TextField
            value={searchWord}
            onChange={onSearchHandler}
            name="search"
            view="outline"
            width="full"
            placeholder={placeholderText}
            autoComplete="off"
            autoFocus
          />
          {isDropdownVisible && (
            <div className={cnSearchForm('Dropdown')}>
              <ul>
                {tagsDataDrop?.map((tag: ITags) => (
                  <li
                    key={tag.tagId}
                    onClick={() => {
                      setSearchWord(tag.tagName);
                      dispatch(setSearchRequest(tag.tagName));
                      dispatch(setSearchModalVisible(false));
                      onClickHandler();
                      history.push(
                        generatePath(`${PATH.SEARCH_PAGE}?tags=${tag.tagId}`, {
                          lang: locale === 'en' ? null : locale,
                        })
                      );
                    }}
                  >
                    <span>{tag.tagName}</span>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
        <Button iconRight={IconArrowRight} type="submit" />
      </form>
    </div>
  );
};
