import React from 'react';
import { cn } from '../../utils/bem';
import './FeaturedCard.scss';
import { Typography } from '@dataartdev/uikit/Typography';
import { Tag } from '@dataartdev/uikit/Tag';
import { Picture } from '@dataartdev/uikit/Picture';
import { Item } from '../ContentBuilder/templates/models/CTI_104.props';
import { generatePath, Link } from 'react-router-dom';
import { PATH } from '../../../router';
import { useIntl } from 'react-intl';
import { IInternalCase, ILogo, ITag } from '../../../types/common';
import { ICard } from '../ContentBuilder/templates/models/CTI_28.props';
import { isExternal } from '../../utils/prepareButtonAttrs';
import { CardPropView } from '../EssenceCard/models';

const cnFeaturedCard = cn('FeaturedCard');

function isItem(props: Item | IInternalCase | ICard): props is Item {
  return (props as Item).tag !== undefined;
}

function isCase(props: Item | IInternalCase | ICard): props is IInternalCase {
  return (props as IInternalCase).fullUrl !== undefined;
}

function isCard(props: Item | IInternalCase | ICard): props is ICard {
  return (props as ICard).url !== undefined;
}

interface CardLinkProps {
  className?: string;
  url: string;
  children: React.ReactNode;
}

const CardLink: React.FC<CardLinkProps> = ({ className, url, children }) => {
  if (!url) return null;
  return isExternal(url) ? (
    <a href={url} className={className}>
      {children}
    </a>
  ) : (
    <Link to={url} className={className}>
      {children}
    </Link>
  );
};

interface CardProps {
  link: string;
  picture?: ILogo;
  type?: string;
  title?: string;
  tag?: string;
  tags?: ITag[];
  view?: CardPropView;
}

const FeaturedCardContent: React.FC<CardProps> = ({
  link,
  picture,
  type,
  title,
  tag,
  tags = [],
  view,
}) => {
  return (
    <CardLink className={cnFeaturedCard()} url={link}>
      {picture && <Picture {...picture} />}
      <div className={cnFeaturedCard('Inner')}>
        <div className={cnFeaturedCard('Top')}>
          {type && (
            <Typography.Text
              parse
              as="span"
              size="xs"
              transform="uppercase"
              weight="bold"
              className={cnFeaturedCard('Subtitle')}
            >
              {type}
            </Typography.Text>
          )}
          {title && (
            <Typography.Title
              size="tiny"
              as="h3"
              className={cnFeaturedCard('Title')}
            >
              {title}
            </Typography.Title>
          )}
        </div>
        {tag && (
          <Tag mode="link" label={tag} className={cnFeaturedCard('Tag')} />
        )}
        {tags?.length > 0 && view === CardPropView.FILLED && (
          <Tag
            mode="link"
            label={tags[0]?.title}
            className={cnFeaturedCard('Tag')}
          />
        )}
      </div>
    </CardLink>
  );
};
export const FeaturedCard: React.FC<Item | IInternalCase | ICard> = props => {
  const { locale } = useIntl();
  if (isItem(props)) {
    const { tag = '', text = '', type = '', link = '/', picture } = props || {};
    return (
      <FeaturedCardContent
        link={link}
        picture={picture}
        type={type}
        title={text}
        tag={tag}
      />
    );
  }
  if (isCase(props)) {
    const { slug, title, logo, categoryText, tags = [] } = props || {};

    const path = generatePath(PATH.CASE_PAGE, {
      slug: slug,
      lang: locale === 'en' ? undefined : locale,
    });

    return (
      <FeaturedCardContent
        link={path}
        picture={logo}
        type={categoryText}
        title={title}
        tag={tags[0].title}
      />
    );
  }
  if (isCard(props)) {
    const { url = '', picture, type, title, tags = [], view } = props || {};

    return (
      <FeaturedCardContent
        link={url}
        picture={picture}
        type={type?.name}
        title={title}
        tags={tags}
        view={view}
      />
    );
  }
  return null;
};
